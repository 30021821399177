@import '../../styles/index.scss';
/**
  盒子px转rem
 */
@function rem($px) {
    @return ($px/75) * 1rem;
}
/**
    字号px转rem
   */
@function remF($px) {
    @return ($px/64) * 0.853333333rem;
}
$content-width: rem(1324);
$content-space-left: calc((100vw - 1324rem / 75) / 2);
$look-orange: #fba100;
$look-yellow: #ffd400;

.download-page {
    width: 100%;
    height: 100%;
    padding: rem(112) 0 rem(160);
    position: relative;
    .download-hint {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
        background-color: rgba($color: #000000, $alpha: 0.7);
        .arrow {
            position: absolute;
            top: rem(40);
            right: rem(40);
            width: rem(144);
            height: rem(144);
        }
        .text-container {
            display: flex;
            align-items: center;
            column-gap: rem(16);
            width: fit-content;
            margin: rem(184) auto 0;
            .browser {
                width: rem(40);
                height: rem(40);
            }
            .text {
                color: white;
                font-size: remF(32);
                font-weight: 500;
            }
        }
    }
    .download-container-mobile {
        background-image: url('./images/mobile-bg.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: top;
        width: 100%;
        padding: rem(120) rem(70);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .download-container-mobile-slogan {
        width: 100%;
        height: auto;
    }
    .downloadbg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        z-index: 1;
        display: block;
    }
    .downloadheader-logo {
        width: rem(268);
        margin-left: rem(80);
        height: rem(132);
    }
    .downloadtitle {
        font-size: remF(48);
        text-align: center;
        font-weight: 400;
        color: #0d0d0e;
        line-height: rem(67);
        margin-top: rem(80);
    }
    .logo-en {
        width: 100%;
    }
    .downloadsubtitle {
        font-size: remF(24);
        font-weight: 400;
        color: #474d57;
        line-height: rem(40);
        margin-top: rem(20);
        white-space: break-spaces;
    }
    .downloadbutton {
        margin-top: rem(100);
        width: calc(100% - 174rem / 75);
        margin-left: rem(87);
        height: rem(100);
        background: #ffc612;
        line-height: rem(100);
        text-align: center;
        border-radius: rem(8);
        font-size: remF(32);
        font-weight: normal;
        color: #202630;
    }
    .downloadbottom {
        display: flex;
        flex-direction: column;
        row-gap: rem(20);
        position: absolute;
        left: 0;
        bottom: rem(144);
        width: 100%;
        align-items: center;
        font-size: remF(26);
        font-weight: 300;
        color: #474d57;
        line-height: rem(32);
    }
}
.download-page-en {
    .downloadtitle {
        font-size: remF(88);
        font-weight: 700;
        color: #fba100;
        line-height: rem(88);
        margin-top: rem(80);
    }
    .downloadsubtitle {
        font-size: remF(36);
        font-weight: 275;
        color: #474d57;
        line-height: rem(48);
        letter-spacing: 0;
        margin-top: rem(16);
    }
}
