@import '../../../../styles/index.scss';
@import '../../../../styles/modules/mobile-function.scss';
.lk-mobile-contack-info {
    width: 100%;
    background-color: white;
    padding: rem(80) rem(32) 0;
    .lk-mobile-contack-info-title {
        width: 100%;
        text-align: center;
        font-size: remF(34);
        font-weight: normal;
        line-height: rem(44);
    }
    .lk-mobile-contack-qr-codes {
        width: 100%;
        margin-top: rem(48);
        display: flex;
        justify-content: center;
        .lk-mobile-contack-qr-code {
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
                width: rem(224);
                height: rem(224);
            }
            .lk-mobile-contack-title {
                //font-family: MiSans-Normal;
                margin-top: rem(16);
                font-size: remF(30);
                font-weight: 400;
                line-height: rem(44);
                letter-spacing: 0px;
                color: #1e2329;
                text-align: center;
                width: 100%;
            }
            .lk-mobile-contack-subtitle {
                //font-family: MiSans-Normal;
                font-size: remF(26);
                transform: scale(0.8);
                font-weight: 400;
                margin-top: rem(4);
                line-height: rem(44);
                letter-spacing: 0px;
                text-align: center;
                color: rgba($color: #474d57, $alpha: 0.7);
            }
            .lk-mobile-contack-subtitle-en {
                line-height: rem(30);
            }
        }
        // .lk-mobile-contack-qr-code + :nth-child(2) {
        //     margin-left: rem(80);
        // }
    }
    .lk-mobile-contack-text-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: rem(30);
        .lk-mobile-contack-text-info-sub1 {
            //font-family: MiSanzs-Medium;
            margin-top: rem(40);
            font-size: remF(38);
            font-weight: 500;
            line-height: rem(56);
            letter-spacing: 0px;
            color: #1e2329;
        }
        .lk-mobile-contack-text-info-sub2 {
            margin-top: rem(4);
            //font-family: Barlow-Regular;
            font-size: remF(24);
            font-weight: normal;
            line-height: rem(40);
            color: #474d57;
        }
        .lk-mobile-contack-text-info-sub3 {
            margin-top: rem(4);
            //font-family: Barlow-Regular;
            font-size: remF(24);
            font-weight: 500;
            line-height: rem(40);
            color: #474d57;
        }
        .lk-mobile-contack-text-info-sub5 {
            //font-family: Barlow-Regular;
            margin-top: rem(40);
            font-size: remF(26);
            font-weight: 300;
            line-height: rem(26);
            letter-spacing: 0px;
            color: #474d57;
            vertical-align: middle;
            .lk-contact-email {
                vertical-align: text-top;
                width: rem(32);
                height: rem(32);
                margin-right: rem(8);
            }
        }
    }
    .lk-mobile-contack-footer {
        margin-top: rem(80);
        height: rem(150);
        display: flex;
        justify-content: center;
        border-top: 1px solid rgba($color: #1e2329, $alpha: 0.1);
        align-items: center;
        .lk-mobile-contack-footer-img {
            height: rem(50);
            width: rem(270);
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: rem(40);
        }
        .lk-mobile-contack-footer-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .lk-mobile-contack-footer-text {
                color: #979fad;
                font-size: remF(22);
                font-weight: 500;
                line-height: rem(32);
            }
            .lk-mobile-contack-footer-text:first-child {
                margin-bottom: rem(4);
            }
        }
    }
}
