@import '../../styles/index.scss';

// @keyframes slideDown {
//     from {
//         transform: translateY(-100%);
//     }
//     to {
//         transform: translateY(0);
//     }
// }

// @keyframes slideUp {
//     from {
//         transform: 0;
//     }
//     to {
//         transform: translateY(0);
//     }
// }
@function rem($px) {
    @return ($px/75) * 1rem;
}
@function remF($px) {
    @return ($px/64) * 0.96rem;
}
video::-webkit-media-controls {
    display: none !important;
}
video:state(webkit-media-controls-fullscreen-button) {
    display: none;
}
//播放按钮
video:state(webkit-media-controls-play-button) {
    display: none;
}
//进度条
video:state(webkit-media-controls-timeline) {
    display: none;
}
//观看的当前时间
video:state(webkit-media-controls-current-time-display) {
    display: none;
}
//剩余时间
video:state(webkit-media-controls-time-remaining-display) {
    display: none;
}
//音量按钮
video:state(webkit-media-controls-mute-button) {
    display: none;
}
video:state(webkit-media-controls-toggle-closed-captions-button) {
    display: none;
}
//音量的控制条
video:state(webkit-media-controls-volume-slider) {
    display: none;
}
//所有控件
video:state(webkit-media-controls-enclosure) {
    display: none;
} /* This used to work for parent element of button divs */

/* But it is not enough now, below dont hide play button parent div */

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
}
video::--webkit-media-controls-play-button {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
}
/* New shadow dom for play button */
/* This one works */
*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}
.container {
    width: 100%;
    margin: auto;
    height: 100%;
    padding: rem(40) rem(24) rem(24);
    display: flex;
    flex-direction: column;
    // padding-bottom: rem(132); // 为固定在底部的元素留出空间
}

.header {
    display: flex;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    justify-content: space-between;
    padding: 0 rem(16);
    .logo {
        height: rem(64);
    }
}
.en-zh {
    background-color: white;
    width: rem(192);
    height: rem(64);
    border-radius: rem(64);
    border: rem(1) solid #eaecef;
    button {
        width: 50%;
        height: 100%;
        text-align: center;
        border: none;
        background-color: transparent;
        font-size: remF(24);
        font-weight: 900;
        color: #202630;
        cursor: pointer;
        padding: 0;
        border-radius: rem(64);
    }
    .active {
        background-color: #ffd400;
    }
}

.background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fafafa;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.profile {
    margin-top: rem(44);
    padding: rem(40);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: rem(16);
    width: 100%;
    .top {
        display: flex;
        align-items: center;
        margin-bottom: rem(40);
        width: 100%;
        .top-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .profileImg {
                background: #f0f0f0;
                width: rem(100);
                height: rem(100);
                border-radius: 50%;
                margin-right: rem(36);
                flex-shrink: 0;
            }
            .desc {
                //撑开剩余空间
                flex: 1;
                min-width: 0; // 防止内容溢出
                .name {
                    margin-bottom: rem(20);
                    font-size: remF(48);
                    font-weight: 600;
                    line-height: rem(64);
                    color: #121518;
                }
                .titles {
                    width: 100%;
                    align-items: center;
                    margin-top: rem(4);
                    font-size: remF(24);
                    line-height: rem(32);
                    font-weight: 300;
                    color: #202630;
                    line-height: rem(32);
                    span + span {
                        margin-left: rem(24);
                    }
                }
            }
        }
    }
}
.slogan {
    display: flex;
    align-items: center;
    justify-content: center;
    .line {
        height: rem(1);
        background-color: #d8d8d8;
        width: rem(30);
    }
    .text {
        margin: 0 rem(30);
        line-height: rem(32);
        font-size: remF(18);
        font-weight: normal;
        color: #999;
    }
}
.profileInfo {
    padding-left: rem(40);
    font-size: remF(26);
}

.investment {
    margin-bottom: rem(20);
}

.investmentItems {
    display: flex;
    justify-content: space-between;
}

.item {
    flex: 1;
    margin: 0 rem(10);
    text-align: center;
}

.locations {
    margin: rem(40) 0;
    padding: rem(40);
    display: flex;
    justify-content: space-between;
    .locationItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: rem(64);
            height: rem(64);
        }
        span {
            margin-top: rem(8);
            font-size: remF(24);
            font-weight: 300;
            color: #4d5159;
            line-height: rem(32);
        }
    }
}

.stickyButton {
    width: 100%;
    z-index: 10;

    .contactButton {
        display: block;
        width: 100%;
        padding: rem(10);
        background-color: #ffd400;
        height: rem(88);
        border: none;
        border-radius: rem(5);
        font-size: rem(28);
        cursor: pointer;
        font-weight: bold;
        line-height: rem(40);
        img {
            width: rem(24);
            height: rem(24);
            margin-left: rem(10);
        }
    }
}

// .modal {
//     position: fixed;
//     bottom: rem(176); // 从按钮上方升起，假设按钮高度为88px
//     left: 0;
//     width: 100%;
//     background-color: white;
//     border-top-left-radius: rem(20);
//     border-top-right-radius: rem(20);
//     box-shadow: 0 rem(-2) rem(10) rgba(0, 0, 0, 0.1);
//     transform: translateY(100%);
//     animation: slideUp 0.3s forwards;
//     z-index: 20; // 确保modal在按钮上方
// }
.modal {
    position: absolute;
    bottom: rem(148); // 按钮高度
    left: rem(0);
    overflow: hidden; // 添加这一行

    right: rem(0);
    border-top-left-radius: rem(16);
    border-top-right-radius: rem(16);
    // transform: translateY(100%);
    // animation: slideDown 0.3s forwards;
    z-index: 20;
}
.modalContent {
    display: flex;
    flex-direction: column;
}

.modalContent button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        width: rem(24);
        height: rem(24);
        margin-right: rem(16);
    }
    background-color: white;
    height: rem(88);
    border: none;
    font-size: remF(28);
    line-height: rem(40);
    font-weight: 500;
    padding: 0 rem(33);
    cursor: pointer;
}
.bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    padding-bottom: rem(60);
}

.footer {
    position: fixed;
    bottom: rem(20);
    width: 100%;
    text-align: center;
    height: rem(40);
    z-index: 10;
    color: #999;
}
