@import './styles/index.scss';

a {
    // color: #fff;
}
button {
    outline: none;
}
.hide-scrollbar {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    // overflow-y: auto;
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    color: transparent;
    display: none;
}
.form-item {
    border-bottom: 1px solid #e1e1e1 !important;
    padding: 5px 0px !important;
    margin-bottom: 0 !important;
    background-color: #fff !important;
}

.ant-form-item-label > label {
    color: #777 !important;
}
video::-webkit-media-controls {
    display: none !important;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
//播放按钮
video::-webkit-media-controls-play-button {
    display: none;
}
//进度条
video::-webkit-media-controls-timeline {
    display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
    display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
    display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
    display: none;
}
//所有控件
video::-webkit-media-controls-enclosure {
    display: none;
} /* This used to work for parent element of button divs */

/* But it is not enough now, below dont hide play button parent div */

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
}
video::--webkit-media-controls-play-button {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
}
/* New shadow dom for play button */
/* This one works */
*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}
html,
body {
    // height: -moz-available; /* WebKit-based browsers will ignore this. */
    // height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    // height: fill-available;
    overflow-x: hidden;
    height: 100%;
}

.vertical-radio-group {
    label {
        display: flex;
        line-height: rem(60);
        align-items: flex-start;

        > span:first-child {
            line-height: rem(60);
            height: rem(60);
            display: inline-flex;
            align-items: center;

            .ant-radio-input {
                top: initial;
                right: initial;
                bottom: initial;
                left: initial;
            }
        }

        > span:last-child {
            word-break: break-all;
            width: rem(600);
            display: inline-block;
            white-space: normal;
        }
    }
}

// 文字换行
.card-pre-wrap {
    white-space: pre-wrap;
}

.text-decoration-underline {
    text-decoration: underline;
}

.padding-left-20 {
    padding-left: rem(20);
}
.padding-left-40 {
    padding-left: rem(40);
}
.padding-left-60 {
    padding-left: rem(60);
}
.padding-left-80 {
    padding-left: rem(80);
}

.text-align-center {
    text-align: center;
}
.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}

.font-weight-bold {
    font-weight: bold;
}
.font-weight-800 {
    font-weight: 800;
}

.font-size-22 {
    font-size: remF(22);
}
.font-size-24 {
    font-size: remF(24);
}
.font-size-26 {
    font-size: remF(26);
}
.font-size-28 {
    font-size: remF(28);
}
.font-size-30 {
    font-size: remF(30);
}
.font-size-32 {
    font-size: remF(32);
}

.font-size-34 {
    font-size: remF(34);
}

.font-size-36 {
    font-size: remF(36);
}

.font-size-38 {
    font-size: remF(38);
}

.font-size-40 {
    font-size: remF(40);
}
.font-size-42 {
    font-size: remF(42);
}
.font-size-44 {
    font-size: remF(44);
}
