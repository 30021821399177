@import '../../styles/index.scss';
// @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @font-face {
//     font-family: 'MILanProVF';
//     src: url('../../assets/MiLan-ProVF.ttf');
// }

$look-orange: #fba100;
$look-yellow: #ffd400;

// html {
//     overflow-y: scroll;
// }

.page-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.page {
    position: absolute;
    height: 100%;
    width: 100%;

    .top-anchor {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 1px;
    }

    .nav {
        width: 100%;
        height: rem(80);
        z-index: 9999;
        position: absolute;
        top: rem(10);
        background-color: transparent;
        transition: transform 0.2s ease-in-out;

        > div {
            width: $content-width;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            z-index: 10;
        }

        .logo {
            // width: rem(114);
            height: rem(32);

            .img {
                // width: 100%;
                height: 100%;
            }
        }

        .menu {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: rem(24);

            .menu-item {
                text-decoration: none;
                // font-family: PingFangSC-Regular;
                font-size: rem(16);
                font-weight: normal;
                color: #0d0e0f;
                letter-spacing: 1px;
                white-space: nowrap;
                text-align: center;
                width: rem(98);
                display: inline-block;
                line-height: rem(40);
                padding: 0;
                height: rem(40);

                &-selected {
                    border-radius: rem(20);
                    // font-family: PingFangSC-Medium;
                    font-weight: 500;
                    // padding: 0 rem(32);
                    background: $look-yellow;
                }
            }

            :first-child {
                margin-left: 0;
            }

            .lang-content {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: remF(18);
                font-weight: 400;
                line-height: rem(24);

                .btn {
                    cursor: pointer;
                    padding: 10px 10px;
                }

                .line {
                    width: 1px;
                    height: 24px;
                    background: #d8d8d8;
                }
            }
        }

        .nav-trading {
            width: 100%;

            > div {
                width: 100%;
            }
        }

        .tradingview-widget-copyright {
            display: none;
        }
    }

    .nav-top {
        position: fixed;
        top: 0;
        background-color: white;

        .nav-trading {
            width: 100%;
            margin: 0;

            > div {
                width: 100%;
                height: 100%;
            }
        }
    }

    .download {
        // width: 100%;
        // height: 100%;
        // display: flex;
        // align-items: center;
        // background-image: url('../../images/carousel1.svg');
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
    }

    .carousel {
        width: 100%;
        height: 100vh;
        position: relative;

        .banner-video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: 19;
            object-fit: cover;
        }

        .carousel-body {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: 20;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            white-space: pre-wrap;

            .title {
                width: rem(610);
                margin-left: $content-space-left;
            }

            .title-en {
                width: rem(1090);
                margin-top: rem(70);
            }

            .desc {
                color: rgba($color: #424242, $alpha: 0.8);
                // font-family: MILanProVF-Light, MILanProVF;
                width: $content-width;
                font-size: 24px;
                font-weight: 300;
                margin-top: rem(24);
                margin-left: $content-space-left;
            }
        }
    }

    .copyright {
        width: 100%;
        height: rem(70);
        background: #fbfbfb;

        > div {
            width: $content-width;
            height: 100%;
            margin: 0 auto;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .left {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                // background-image: url('../../images/mini+logo.png');
                width: rem(24);
                height: rem(24);
                background-size: 100% 100%;
            }

            .title {
                // margin-left: rem(8);
                // font-family: 'Barlow-SemiBold';
                // font-family: 'Barlow', 'Helvetica Neue', Helvetica, Arial, sans-serif;
                // font-weight: 700;

                font-size: 14px;
                color: #979fad;
                line-height: rem(24);
            }
        }

        .right {
            // font-family: 'PingFang-SC-Medium';
            // font-weight: 500;
            font-size: 14px;
            color: #979fad;
            cursor: pointer;
        }
    }
}

.page1024 {
    .nav {
        > div {
            width: $content-width1024;
        }

        height: rem(64);

        .logo {
            // width: rem(95);
            height: rem(32);
        }

        .menu {
            display: flex;
            align-items: center;
            margin-right: -30px;
            justify-content: flex-end;
            column-gap: rem(4);

            .menu-item {
                width: rem(80);
                font-size: rem(14);
                display: inline-block;
                line-height: rem(36);
                height: rem(36);

                &-selected {
                    border-radius: rem(36);
                    font-weight: 500;
                    background: $look-yellow;
                }
            }

            .lang-content {
                font-size: remF(14);
            }
        }
    }

    .carousel {
        .carousel-body {
            .title {
                width: rem(480);
                margin-left: $content-space-left1024;
            }

            .title-en {
                width: $content-width1024;
            }

            .desc {
                width: $content-width1024;
                margin-top: rem(24);
                margin-left: $content-space-left1024;
                font-size: rem(20);
            }
        }
    }

    .copyright {
        height: rem(60);

        > div {
            width: $content-width1024;
        }
    }
}

.pageEn {
    font-family: Barlow, Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC',
        'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;

    .nav {
        .menu {
            column-gap: rem(24);

            .menu-item {
                width: auto;
                padding: initial;
                display: inline-block;
                line-height: rem(36);
                height: rem(36);

                &-selected {
                    font-weight: 500;
                    background: transparent;
                    border-radius: 0;
                    border-bottom: 4px solid #ffd400;
                }
            }
        }
    }
}
